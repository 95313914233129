<script setup>
import CopyToClipboard from 'core-ui/components/common/copyToClipboard'

const icon = (severity) => {
  switch (severity) {
    case 'success':
      return 'checkmark'
    case 'warn':
    case 'error':
      return 'warning'
    default:
      return 'info'
  }
}
</script>

<template>
  <p-toast position="top-right" style="top: 20px; right: 40px;">
    <template #message="{ message }">
      <fluency-icon :type="icon(message.severity)" size="lg" style="top: -1px"/>
      <div class="p-toast-message-text">
        <span v-if="message.summary" class="p-toast-summary">
          {{ message.summary }}
          <copy-to-clipboard v-if="message.errorId"
                             :val="message.errorId"
                             :icon-before="true"
                             class="py-0 px-2"
                             btn-classes="no-focus text-dark" />
        </span>
        <div class="p-toast-detail" v-html="message.detail"></div>
      </div>
    </template>
    <template #closeicon>
      <fluency-icon type="close"></fluency-icon>
    </template>
  </p-toast>
</template>
