<template>
  <div class="blueprint-previewer">
    <t-modal :visible="modalVisible"
             @change="modalVisible = $event"
             lazy
             body-class="blueprint-previewer-modal pt-0"
             title-class="d-flex align-items-center"
             size="lg"
             ok-only
             ok-title="Got it"
             @hidden="modalHidden()"
             @show="modalOpened()">
      <template #modal-title>
        <h3>
          <fluency-icon type="blueprint" class="mr-3" />
          Blueprint Previewer
        </h3>
      </template>

      <template #default>
        <div id="blueprint-previewer-main">
          <template v-if="loading">
            <fluency-loader />
          </template>
          <template v-else-if="blueprint">

            <div class="scroll-fixer sticky-top bg-white"></div>
            <!-- BLUEPRINT -->
            <t-card :header-class="['card-header-blueprint', 'sticky-top', classes.v2.cardHeader]"
                    class="bp-card bg-white mt-0">
              <template #header>
                <fluency-icon type="blueprint" :class="[classes.v2.cardIcon, 'icon-color-bp']" />
                <h4 :class="classes.v2.cardTitle"><b>Blueprint:</b> {{ blueprint['templateName'] }}</h4>
              </template>
              <a :id="`${scrollerName}-${sKey(0)}-top`" />

              <!-- BLUEPRINT CONTENT -->
              <t-row class="mr-3">
                <blueprint-previewer-field v-for="(bpField) in fields.blueprint"
                                           :key="`${bpField}`"
                                           :label="bpField"
                                           :highlight="bpField === 'status'"
                                           :value="blueprint[bpField]"
                                           :meta="planMeta"
                                           :config="blueprintConfig"
                                           :tag-data="tagData" />
              </t-row>

              <!-- CAMPAIGNS -->
              <template v-if="blueprint.templateContent && blueprint.templateContent.campaigns">
                <t-card v-for="(cmp, cmpKey) in blueprint.templateContent.campaigns"
                        :key="`${cmpKey}`"
                        :header-class="['card-header-campaign', 'position-sticky', classes.v2.cardHeader]"
                        class="cmp-card my-3"
                        :class="[highlightCurrent(cmpKey)]">
                  <template #header>
                    <fluency-icon type="campaign" :class="[classes.v2.cardIcon, 'icon-color-cmp']" />
                    <h4 :class="classes.v2.cardTitle"><b>Campaign:</b> {{ cmp['Campaign Name'] }}</h4>
                  </template>
                  <a :id="`${scrollerName}-${sKey(0, cmpKey)}-top`" />

                  <!-- CAMPAIGN CONTENT -->
                  <t-row class="mr-3">
                    <blueprint-previewer-field v-for="(cmpField) in fields.campaign"
                                               :key="`${cmpKey}-${cmpField}`"
                                               :label="cmpField"
                                               :value="cmp[cmpField]"
                                               :meta="planMeta"
                                               :config="blueprintConfig"
                                               :tag-data="tagData" />
                  </t-row>

                  <!-- AD GROUPS -->
                  <t-card v-for="(ag, agKey) in cmp.adgroups"
                          :key="`${cmpKey}-${agKey}`"
                          :header-class="['card-header-adgroup', 'position-sticky', classes.v2.cardHeader]"
                          class="ag-card"
                          :class="[highlightCurrent(cmpKey, agKey)]">
                    <template #header>
                      <fluency-icon type="adgroup" :class="[classes.v2.cardIcon, 'icon-color-ag']" />
                      <h4 :class="classes.v2.cardTitle"><b>Ad Group:</b> {{ ag['Ad Group Name'] }}</h4>
                    </template>
                    <a :id="`${scrollerName}-${sKey(0, cmpKey, agKey)}-top`" />

                    <!-- AD GROUP CONTENT -->
                    <t-row class="mr-3">
                      <blueprint-previewer-field v-for="(agField) in fields.adgroup"
                                                 :key="`${cmpKey}-${agKey}-${agField}`"
                                                 :label="agField"
                                                 :value="ag[agField]"
                                                 :meta="planMeta"
                                                 :config="blueprintConfig"
                                                 :show="showAdGroupField(cmp, agField)"
                                                 :tag-data="tagData" />
                    </t-row>

                    <!-- KEYWORDS -->
                    <t-card v-if="ag.keywords && ag.keywords.length > 0"
                            :header-class="['card-header-keywords', 'position-sticky', classes.v2.cardHeader]"
                            class="kw-card">
                      <template #header>
                        <fluency-icon type="keyword" :class="[classes.v2.cardIcon, 'icon-color-kw']" />
                        <h4 :class="classes.v2.cardTitle"><b>Keywords:</b></h4>
                      </template>
                      <a :id="`${scrollerName}-${sKey(0, cmpKey, agKey, 0, 'k')}-top`" />

                      <!-- KEYWORD CONTENT -->
                      <t-row v-if="ag.keywords">
                        <t-col cols="12" class="bp-preview-entry-wrap">
                          <div class="bp-preview-entry d-flex my-2 pt-2">
                            <div class="td-kw-phrase pr-3 font-weight-bold text-left">Keyword Phrase:</div>
                            <div class="td-kw-match-type pr-3 font-weight-bold">Match Type:</div>
                            <div class="td-kw-bid font-weight-bold">Override Bid:</div>
                            <div class="td-kw-tags font-weight-bold">Tags:</div>
                          </div>
                        </t-col>
                      </t-row>
                      <t-row class="mr-3">
                        <blueprint-previewer-field v-for="(kw, kwKey) in ag.keywords"
                                                   :key="`${cmpKey}-${agKey}-${kwKey}`"
                                                   :label="'ABC'"
                                                   :value="kw"
                                                   :meta="planMeta"
                                                   :config="blueprintConfig"
                                                   :creative="true"
                                                   :creative-keys="fields.keyword"
                                                   :tag-data="tagData" />
                      </t-row>
                    </t-card>

                    <!-- CREATIVES -->
                    <t-card v-for="(cr, crKey) in ag.creatives"
                            :key="`${cmpKey}-${agKey}-${crKey}`"
                            :header-class="['card-header-creative', 'position-sticky', classes.v2.cardHeader]"
                            class="cr-card">
                      <template #header>
                        <fluency-icon type="creative" :class="[classes.v2.cardIcon, 'icon-color-cr']" />
                        <h4 :class="classes.v2.cardTitle"><b>Creative:</b> #{{crKey + 1}}</h4>
                      </template>
                      <a :id="`${scrollerName}-${sKey(0, cmpKey, agKey, crKey, 'cr')}-top`" />

                      <!-- CREATIVE CONTENT -->
                      <t-row class="mr-3">
                        <template v-for="(crField) in Object.keys(cr)" :key="`${cmpKey}-${agKey}-${crKey}-${crField}`">
                          <blueprint-previewer-field v-if="showCreative(crField, cr[crField])"
                                                     :label="crField"
                                                     :value="cr[crField]"
                                                     :meta="planMeta"
                                                     :config="blueprintConfig"
                                                     :tag-data="tagData" />
                        </template>
                      </t-row>

                    </t-card>
                  </t-card>
                </t-card>
              </template>
            </t-card>

            <!--<pre>{{blueprint}}</pre>-->
          </template>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import _delay from 'lodash.delay'
import _countBy from 'lodash.countby'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import BlueprintPreviewerField from './blueprintPreviewerField'

export default {
  name: 'blueprintPreviewer',
  components: { BlueprintPreviewerField, FluencyLoader },
  data () {
    return {
      modalVisible: false,
      eventName: 'blueprint-previewer-open',
      scrollerName: 'blueprint-previewer',
      removeWhitespace: this.$filters.removeWhitespace,
      tagData: {},
      sKey: this.$blueprints.blueprintIndexer,
      loading: false,
      selectedKey: null,
      blueprint: {},
      accountPlanId: null,
      planType: null,
      planMeta: null,
      classes: {
        v2: {
          cardHeader: 'd-flex align-items-center overflow-hidden',
          cardIcon: 'mr-3',
          cardTitle: 'flex-grow-1 mr-5 h5 font-weight-light'
        }
      },
      fields: {
        blueprint: [
          'status',
          'contentTemplateId',
          'vertical',
          'updatedOn'
        ],
        campaign: [
          'Campaign Name',
          'Campaign Type',
          'campaignGroup',
          'Default Budget',
          'Geo Description',
          'Ad Schedule Strategy',
          'Campaign Negative Keywords',
          'Bidding Strategy',
          'Campaign Launch Status',
          'Final URL Suffix'
        ],
        adgroup: [
          'Ad Group Name',
          'Preferred URL Path',
          'Default Bid',
          'Ad Group Launch Status',
          'Final URL Suffix',
          'Ad Group Negative Keywords',
          'productSet'
        ],
        keyword: [
          // Don't mess with the order of these unless you adjust in blueprintPreviewField.vue as well. - GT
          'Keyword Phrase',
          'Match Types',
          'Override Bid'
        ],
        creative: [
          'Creative Type',
          'creativeName',
          '*headline*',
          '*description*',
          '*path*',
          '*image*',
          'businessName',
          'phoneNumber',
          'titles',
          'bodies',
          'leadFormOnly'
        ]
      }
    }
  },
  computed: {
    activeItem () {
      return this.$store.getters.activeItem
    },
    // blueprintSkeleton () {
    //   return this.$store.getters.skeletons?.strategy
    // },
    blueprintConfig () {
      return this.$store.getters.strategyEditorFields
    }
  },
  async created () {
    await Promise.all([
      this.$store.dispatch('seConfig')
    ])
  },
  async mounted () {
    this.reset()

    this.$eventBus.$on(this.eventName, this.init)
  },
  beforeUnmount () {
    this.$eventBus.$off(this.eventName, this.init)
  },
  methods: {
    async init (payload) {
      this.loading = true
      this.modalVisible = true

      // console.log('=payload=', payload)
      // console.log('=contentTemplateId=', payload.contentTemplateId)
      // console.log('=accountPlanId=', payload.accountPlanId)
      // console.log('=campaignPlanId=', payload.campaignPlanId)
      // console.log('=adGroupPlanId=', payload.adGroupPlanId)
      // console.log('=keywordPlanId=', payload.keywordPlanId)
      // console.log('=creativePlanId=', payload.creativePlanId)

      const accountPlanId = payload?.accountPlanId
      const formula = payload?.formula
      let forType = null
      let forId = null
      this.accountPlanId = accountPlanId

      if (payload?.keywordPlanId) {
        forType = 'keyword'
        forId = payload.keywordPlanId
      } else if (payload?.creativePlanId) {
        forType = 'creative'
        forId = payload.creativePlanId
      } else if (payload?.adGroupPlanId) {
        forType = 'adGroup'
        forId = payload.adGroupPlanId
      } else if (payload?.campaignPlanId) {
        forType = 'campaign'
        forId = payload.campaignPlanId
      }

      this.planType = forType

      const blueprintId = payload?.contentTemplateId

      if (blueprintId) {
        await this.fetchBlueprint(blueprintId)
      }

      if (this.blueprint) {
        await this.fetchTagData()
      }

      if (payload?.keywordPlanId) {
        await this.fetchPlan('keyword', { keywordPlanId: payload.keywordPlanId })
      }
      if (payload?.creativePlanId) {
        await this.fetchPlan('creative', { creativePlanId: payload.creativePlanId })
      }
      if (payload?.adGroupPlanId) {
        await this.fetchPlan('adGroup', { adGroupPlanId: payload.adGroupPlanId })
      }
      if (payload?.campaignPlanId) {
        await this.fetchPlan('campaign', { campaignPlanId: payload.campaignPlanId })
      }

      if (blueprintId && formula && accountPlanId && forType) {
        await this.fetchPaths(forId, forType, accountPlanId)
      }
      this.loading = false
    },
    showAdGroupField (cmp, agField) {
      if (agField === 'productSet' && (!cmp?.['Campaign Type'] || cmp['Campaign Type']) !== 'FACEBOOK') {
        return false
      }

      return true
    },
    showCreative (key, val) {
      if (!val) {
        return false
      }

      let returnVal = false

      this.fields.creative.forEach(f => {
        if (key.toLowerCase() === f.toLowerCase()) {
          returnVal = true
        }

        if (f.startsWith('*') && f.endsWith('*')) {
          const wildcard = f.replaceAll('*', '')
          if (key.toLowerCase().indexOf(wildcard.toLowerCase()) > -1) {
            returnVal = true
          }
        } else if (f.startsWith('*')) {
          const wildcard = f.replaceAll('*', '')
          if (key.toLowerCase().endsWith(wildcard.toLowerCase())) {
            returnVal = true
          }
        } else if (f.endsWith('*')) {
          const wildcard = f.replaceAll('*', '')
          if (key.toLowerCase().startsWith(wildcard.toLowerCase())) {
            returnVal = true
          }
        }
      })

      return returnVal
    },
    reset () {
      this.loading = false
      this.blueprint = {}
    },
    modalHidden () {
      this.reset()
    },
    modalOpened () {},
    async fetchPlan (planType, payload) {
      const planResponse = await this.$res.fetch.plan(planType, payload)

      const map = this.planMeta || new Map()

      if (planResponse?.[0]?.[`${planType}PlanMetaList`]) {
        planResponse[0][`${planType}PlanMetaList`].forEach(m => {
          map.set(m.metaKey, m.metaValue)
        })
        this.planMeta = map
      }
    },
    async fetchTagData (tag = null) {
      // const tmp = { template: (this.blueprint.hasOwnProperty('templateContent')) ? this.blueprint.templateContent : {} }
      const tmp = {}

      const newAvailableFields = await this.$res.fetch.getAvailableFields(tmp, {
        accountPlanId: this.accountPlanId,
        contentTemplateId: this.blueprint?.contentTemplateId,
        vertical: this.blueprint?.vertical,
        asTree: false
      })

      if (newAvailableFields?.strategyData?.strategyTagCollections) {
        const dataObj = {}

        Object.values(newAvailableFields.strategyData.strategyTagCollections).forEach((type, typeKey) => {
          dataObj[type.category] = {}

          if (type?.strategyTags) {
            type.strategyTags.forEach(t => {
              if (t.data && t.data.length > 0) {
                dataObj[type.category][t.tag] = t.data
              }
            })
          }
        })
        this.tagData = dataObj
      }
    },
    async fetchPaths (forId, forType, accountPlanId) {
      const query = { id: forId, forType: forType.toLowerCase() }
      const payload = { accountPlanId }
      const pathResolverResponse = await this.$res.fetch.blueprintPathResolver(payload, query)

      if (pathResolverResponse) {
        const v = this
        const scrollKey = this.findElementKey(pathResolverResponse)

        if (scrollKey) {
          this.$nextTick(() => {
            v.scrollMainContent(scrollKey)
          })
        }
      }
    },
    async fetchBlueprint (id) {
      const response = await this.$res.fetch.template({ contentTemplateId: id }, true)

      if (response) {
        const blueprint = _cloneDeep(response[0])

        if (blueprint?.templateContent) {
          blueprint.templateContent = JSON.parse(blueprint.templateContent)
        }
        this.blueprint = blueprint
      }
    },
    findElementKey (paths) {
      const k = {
        bp: 0,
        cmp: -1,
        ag: -1
      }

      const bp = this.blueprint?.templateContent
      if (paths?.campaignFormula && bp?.campaigns) {
        bp.campaigns.forEach((cmp, cmpKey) => {
          if (paths.campaignFormula === cmp['Campaign Name']) {
            k.cmp = cmpKey

            if (paths?.adGroupFormula && cmp?.adgroups) {
              cmp.adgroups.forEach((ag, agKey) => {
                if (paths.campaignFormula === cmp['Campaign Name'] && paths.adGroupFormula === ag['Ad Group Name']) {
                  k.ag = agKey
                }
              })
            }
          }
        })
      }

      const sKey = this.sKey(k.bp, k.cmp, k.ag)
      this.selectedKey = sKey

      return (k.cmp !== -1) ? sKey : false
    },
    scrollMainContent (id) {
      const rws = this.removeWhitespace
      const st = this.$scrollTo
      const sassHeight = -40 // $stackableStickyHeaderHeight
      const sassOffset = -20 // $stackableStickyHeaderOffset
      const numSteps = _countBy(id)['-']
      const offset = (sassHeight * numSteps) + sassOffset - numSteps
      const scrollerName = this.scrollerName

      _delay(function () {
        st(rws(`#${scrollerName}-${id}-top`), 500, {
          offset: offset,
          force: true,
          container: '.blueprint-previewer-modal'
        })
      }, 250)
    },
    highlightCurrent (cmpKey, agKey = -1) {
      if (this.sKey(0, cmpKey, agKey) === this.selectedKey) {
        return 'highlight-card'
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
$stackableStickyHeaderHeight: 40px;
$stackableStickyHeaderOffset: 20px;

// CARDS
.card-header {
  margin-left: 0;
  margin-right: 0;
  padding-left: 1rem;
  padding-right: 0;
  border-radius: 0;
  min-height: $stackableStickyHeaderHeight;
  height: $stackableStickyHeaderHeight;
  max-height: $stackableStickyHeaderHeight;
}
.card-body {
  margin-left: 0;
  margin-right: -1px;
  padding-left: 10px;
  padding-right: 0;
}
.bp-card,
.cmp-card,
.ag-card,
.cr-card,
.kw-card {
  border-top-width: 0;
  border-color: #dee2e6;
}

// HEADER COLORS
.card-header-blueprint {
  background-color: #f7f7f7;
  box-shadow: 6px 0 inset $color-key-blueprint, 0 1px inset #dee2e6, 0 -1px inset #dee2e6;
}
.card-header-campaign {
  background-image: linear-gradient(to right, rgba($color-key-campaign, 0.1), rgba($color-key-campaign, 0.1)),
    linear-gradient(to right, white, white);
  box-shadow: 6px 0 inset $color-key-campaign, 0 1px inset #dee2e6, 0 -1px inset #dee2e6;
}
.card-header-adgroup {
  background-image: linear-gradient(to right, rgba($color-key-adgroup, 0.1), rgba($color-key-adgroup, 0.1)),
    linear-gradient(to right, white, white);
  box-shadow: 6px 0 inset $color-key-adgroup, 0 1px inset #dee2e6, 0 -1px inset #dee2e6;
}
.card-header-creative {
  background-image: linear-gradient(to right, rgba($color-key-creative, 0.1), rgba($color-key-creative, 0.1)),
    linear-gradient(to right, white, white);
  box-shadow: 6px 0 inset $color-key-creative, 0 1px inset #dee2e6, 0 -1px inset #dee2e6;
}
.card-header-keywords {
  background-image: linear-gradient(to right, rgba($color-key-keywords, 0.1), rgba($color-key-keywords, 0.1)),
    linear-gradient(to right, white, white);
  box-shadow: 6px 0 inset $color-key-keywords, 0 1px inset #dee2e6, 0 -1px inset #dee2e6;
}

.icon-color-{
  &bp { color: $color-key-blueprint; }
  &cmp { color: $color-key-campaign; }
  &ag { color: $color-key-adgroup; }
  &cr { color: $color-key-creative; }
  &kw { color: $color-key-keywords; }
}

// HEADER POSITIONS (FOR STACKING)
.scroll-fixer {
  height: $stackableStickyHeaderOffset;
}
.card-header-blueprint {
  top: $stackableStickyHeaderOffset;
  z-index: 100;
}
.card-header-campaign {
  top: ($stackableStickyHeaderHeight) - 1 + $stackableStickyHeaderOffset;
  z-index: 99;
}
.card-header-adgroup {
  top: ($stackableStickyHeaderHeight * 2) - 2 + $stackableStickyHeaderOffset;
  z-index: 98;
}
.card-header-creative,
.card-header-keywords {
  top: ($stackableStickyHeaderHeight * 3) - 3 + $stackableStickyHeaderOffset;
  z-index: 97;
}

.card.highlight-card {
  &.cmp-card {
    border: 3px solid $color-key-campaign;
  }
  &.ag-card {
    border: 3px solid $color-key-adgroup;
  }
}

.row {
  .td-kw-phrase {
    width: 200px;
  }
  .td-kw-match-type {
    width: 140px;
  }
  .td-kw-bid {
    width: 300px;
  }
  .td-kw-tags {
    width: auto;
  }
}
</style>
<style lang="scss">
.blueprint-previewer-modal .row {
  .bp-preview-entry-wrap:first-child .bp-preview-entry {
    border-top: none !important;
  }

  .td-kw-phrase {
    width: 200px;
  }

  .td-kw-match-type {
    width: 140px;
  }

  .td-kw-bid {
    width: 300px;
  }
}
</style>
