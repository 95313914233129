<template>
  <div v-if="isAllowed" class="fluent-ai-chat">
    <!-- bubble style -->
    <div v-if="bubbleStyle">
      <div class="mb-2"><fluency-icon type="muse" size="sm"/> Muse</div>
      <div class="d-inline-block p-4 text-bubble muse bg-white">
        {{ computedTitle }}
      </div>

      <div v-for="(prompt, index) in history" :key="'response' + index">
        <div v-if="prompt.userPrompt" class="text-right">
          <div class="mt-4 mb-2">You</div>
          <div class="d-inline-block p-4 text-bubble you bg-info text-white">
            {{prompt.userPrompt}}
          </div>
        </div>
        <div class="position-relative mb-2">
          <div>
            <div v-if="prompt.userPrompt" class="mt-4 mb-2"><fluency-icon type="muse" size="sm"/> Muse</div>
            <div class="d-inline-block p-4 text-bubble muse bg-white">
              <div v-if="prompt.modelResponse" class="prompt-html" v-markdown="prompt.modelResponse"></div>
              <fluency-loader class="prompt-loader" v-if="index === history.length - 1 && loading" inline dots></fluency-loader>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showBubbleSuggestions" class="suggestions">
        <div v-for="(suggestion, index) in suggestions" :key="`suggestion-${index}`" class="d-inline-block mt-2 mr-2 px-3 py-1 border text-bubble suggestion hover" role="button" @click="userPrompt = suggestion.subText || suggestion; chatPrompt()">
          {{ suggestion.subText || suggestion }}
        </div>
      </div>
    </div>

    <!-- panel style -->
    <div v-else class="fluent-ai-chat d-flex flex-column h-100">
      <div id="fluentGptScrollArea" class="flex-shrink-1 overflow-auto-y mb-4 py-4">
        <div class="d-flex align-items-start mb-5">
          <fluency-icon type="muse" class="mr-3"></fluency-icon>
          <span>{{title}}</span>
        </div>
        <div v-for="(prompt, index) in history" :key="'response' + index">
          <div v-if="prompt.userPrompt" class="mb-5 d-flex justify-content-end">
            <div class="px-4 py-3 rounded user-prompt" style="width: 75%; white-space: pre-line;">{{prompt.userPrompt}}</div>
          </div>
          <div v-if="prompt.modelResponse" class="d-flex align-items-start mb-5">
            <fluency-icon type="muse" class="mr-3"></fluency-icon>
            <div class="model-response">
              <div v-markdown="prompt.modelResponse"></div>
              <div v-if="prompt.actions && prompt.actions.broadcast"><broadcast-suggestions :provided-account-broadcasts="prompt.actions.broadcast"></broadcast-suggestions></div>
              <div v-else-if="prompt.actions && prompt.actions.analyzeCampaign"><analyze-campaign :campaign-plan-prop="prompt.actions.analyzeCampaign" :inside-muse="true" @campaign-loaded="void(0)" /></div>
              <template v-if="hasSessionStorage && prompt.datasetDirectives">
                <a v-for="(dataset, datasetKey) in prompt.datasetDirectives"
                       :key="datasetKey"
                       class="text-info cursor-pointer"
                       @click="loadDataset(prompt.datasetDirectives, datasetKey)">View Supporting Data</a>
              </template>
            </div>
          </div>
          <div v-if="index === history.length - 1 && loading" class="mt-4 d-flex justify-content-center">
            <fluency-loader  inline dots ></fluency-loader>
          </div>
        </div>
      </div>
      <div class="fluent-ai-input mt-auto">
        <div class="d-flex align-items-end justify-content-end flex-wrap">
          <t-button v-if="history.length > 0" variant="ghost-info" class="mb-3" @click="clearHistory()">Clear Chat History</t-button>
        </div>
        <div>
          <p-icon-field>
            <p-input-icon style="font-size: 0.9rem;"
                          :class="{'text-body cursor-pointer': userPrompt.length === 0, 'text-disabled pointer-events-none': userPrompt.length > 0}"
                          @click="userPrompt = '/', focusInput()">
              <fluency-icon type="slashCommand"></fluency-icon>
            </p-input-icon>
            <p-textarea id="muse-chat-textarea"
                        :placeholder="placeholder"
                        :tabindex="500"
                        class="w-100 p-inputtext p-inputtext-lg"
                        v-model="userPrompt"
                        autoResize
                        rows="1"
                        size="large"
                        @focus="setIsFocused"
                        @keyup.enter="chatPrompt()"></p-textarea>
            <p-input-icon style="font-size: 0.9rem;" class="cursor-pointer text-body" @click="chatPrompt()">
              <fluency-icon type="send"></fluency-icon>
            </p-input-icon>
          </p-icon-field>
          <ai-slash-commands v-if="isFocused" ref="aiSlashCommands" :prompt="userPrompt" target="muse-chat-textarea" @input="setSlashCommand($event)"></ai-slash-commands>
        </div>
        <div class="d-flex justify-content-center py-4">
          <small class="text-muted d-block">AI can make mistakes. Check your data.</small>
        </div>
      </div>
    </div>
    <async-fetcher v-if="enable"
                   hide-loader
                   :timeout="300"
                   ref="asyncFetcher"
                   @start="loading = true"
                   @end="loading = false"
                   @kill="loading = false"
                   @status="handleStatus($event)"
                   @success="handleSuccess($event)"></async-fetcher>
  </div>
</template>
<script>
import AsyncFetcher from 'core-ui/components/common/asyncFetcher'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import BroadcastSuggestions from 'core-ui/components/common/broadcastSuggestions.vue'
import AnalyzeCampaign from '@/components/pages/manage/campaign/analyzeCampaign.vue'
import AiSlashCommands from '~/components/common/aiSlashCommands'
import { useMusePanel } from 'core-ui/composables/useMusePanel'

export default {
  name: 'fluentGpt',
  components: { AsyncFetcher, FluencyLoader, BroadcastSuggestions, AiSlashCommands, AnalyzeCampaign },
  props: {
    minHeight: {
      type: Number,
      default: 500
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Ask Muse'
    },
    title: {
      type: String,
      default: 'How Can I Help You Today?'
    },
    csvData: {
      type: [String, Function]
    },
    jsonData: {
      type: String
    },
    immediatePrompt: {
      type: Boolean
    },
    immediatePromptText: {
      type: String
    },
    planType: {
      type: String
    },
    planTypeId: {
      type: Object
    },
    enable: {
      type: Boolean,
      default: true
    },
    bubbleStyle: {
      type: Boolean,
      default: false
    },
    inputPrompt: {
      type: String
    },
    forceAllow: {
      type: Boolean,
      default: false
    },
    showCredits: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const { history, setHistory, dataCollectors, promptLoading } = useMusePanel()
    return { historyRef: history, setHistory, dataCollectors, promptLoading }
  },
  watch: {
    inputPrompt: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.userPrompt = newVal
          this.chatPrompt()
        }
      }
    },
    enable: {
      immediate: true,
      handler: function (newVal) {
        if (this.immediatePrompt && newVal) {
          if (!this.userPrompt) {
            if (this.immediatePromptText) {
              this.userPrompt = this.immediatePromptText
            } else {
              this.userPrompt = this.planPrompt
            }
          }
          this.$nextTick(() => {
            this.chatPrompt()
          })
        }
        if (newVal === false) { // if
          this.loading = false
        }
      }
    }
  },
  data () {
    return {
      userPrompt: '',
      scrollParent: undefined,
      isSinglePlanPrompt: false,
      isFocused: false
    }
  },
  computed: {
    loading: {
      get () {
        return this.promptLoading
      },
      set (value) {
        this.promptLoading = value
      }
    },
    history: {
      get () {
        return this.historyRef
      },
      set (value) {
        this.setHistory(value)
      }
    },
    user () {
      return this.$store.getters?.user
    },
    isInternal () {
      return this.user.internalUser
    },
    isAllowed () {
      return this.user?.capabilities?.AIChat || this.forceAllow
    },
    userInitials () {
      return `${this.user?.firstName?.charAt(0) || ''}${this.user?.lastName?.charAt(0) || ''}`
    },
    showSinglePlanPrompt () {
      return this.planType && this.planTypeId
    },
    showBubbleSuggestions () {
      if (this.suggestions.length <= 0 || this.loading) {
        return false
      }
      // has to be finished loading, and theres no user prompt
      return !this.loading && !this.history[this.history.length - 1]?.userPrompt
    },
    computedTitle () {
      if (this.isSinglePlanPrompt) {
        return `What Can We Tell You About Your ${this.$filters.convertFromCamelCase(this.planType)}?`
      }
      return this.title
    },
    hasSessionStorage () {
      return !!sessionStorage
    },
    planPrompt () {
      return `Please analyze the following advertising ${this.$filters.convertFromCamelCase(this.planType)}, highlight strong performance if present, identify poor performing content, and recommend adjustments to be made to improve performance`
    }
  },
  methods: {
    setIsFocused (event) {
      this.isFocused = true
      document.addEventListener('click', this.clickOff)
    },
    clickOff (event) {
      if (event.target.id === 'muse-chat-textarea') {
        return
      } else if (document.activeElement.id === 'muse-chat-textarea') {
        return
      } else if (this.userPrompt.charAt(0) === '/') {
        const pop = document.getElementById('muse-slash-command-popover')
        if (pop && (event.target === pop || pop.contains(event.target))) {
          return
        }
      }
      this.isFocused = false
      document.removeEventListener('click', this.clickOff)
    },
    setSlashCommand (command) {
      this.userPrompt = `/${command} `
      document.removeEventListener('click', this.clickOff)
      this.focusInput()
    },
    async remainingBalance () {
      if (typeof this.$res?.ai?.balance !== 'function') { // need this here for colab instance where we dont have a balance endpoint
        return
      }
      const response = await this.$res?.ai?.balance()
      if (response) {
        this.$emit('newBalance', response)
      }
    },
    promptAboutPlan () {
      this.userPrompt = this.planPrompt
      this.isSinglePlanPrompt = true
      this.$nextTick(() => {
        this.chatPrompt()
      })
    },
    focusInput () {
      const el = document.getElementById('muse-chat-textarea')
      if (el) {
        el.focus()
      }
    },
    addSuggestion (suggestion) {
      this.userPrompt = suggestion.value || suggestion.subText
      this.focusInput()
    },
    clearHistory () {
      this.history = []
    },
    chatPrompt () {
      if (!this.loading && this.userPrompt) {
        const userPrompt = this.userPrompt
        this.userPrompt = ''
        const historyWithUserPrompts = this.history.filter(h => h.userPrompt)
        const history = (historyWithUserPrompts.length > 20 ? historyWithUserPrompts.slice(-20) : historyWithUserPrompts)
        const promptWithHistory = {
          history,
          current: {
            userPrompt
          }
        }
        if (this.dataCollectors.length > 0) {
          const jsonData = []
          const csvData = []
          for (const cb of this.dataCollectors) {
            const data = cb()
            if (typeof data === 'object') {
              jsonData.push(JSON.stringify(data))
            } else {
              csvData.push(data)
            }
          }
          if (jsonData.length > 0) {
            promptWithHistory.current.jsonData = JSON.stringify(jsonData)
          }
          if (csvData.length > 0) {
            promptWithHistory.current.csvData = JSON.stringify(csvData)
          }
        }
        if (this.isSinglePlanPrompt && promptWithHistory.history.length === 0) {
          this.$refs.asyncFetcher.asyncFetch(this.$res.ai.chatPromptPlanType, [this.planType, this.planTypeId])
        } else {
          this.$refs.asyncFetcher.asyncFetch(this.$res.ai.chatPrompt, [promptWithHistory])
        }
        this.history.push({
          userPrompt,
          modelResponse: ''
        })
        this.scrollToBottom(true)
      }
    },
    handleSuccess (success) {
      const finished = success
      const lastIndex = this.history.length - 1
      this.history[lastIndex] = finished
      if (this.bubbleStyle) {
        this.promptUserAgain()
      }
      this.remainingBalance()
      this.scrollToBottom()
    },
    handleStatus (status) {
      const lastIndex = this.history.length - 1
      const userPrompt = this.history[lastIndex]?.userPrompt
      if (!userPrompt) return
      this.history[lastIndex] = { userPrompt, modelResponse: status.content.join('') }
      this.$setCompat(this.history, lastIndex, { userPrompt, modelResponse: status.content.join('') })
      this.scrollToBottom()
    },
    scrollToBottom (forceScroll) {
      const bottom = (this.scrollParent.scrollHeight - this.scrollParent.offsetHeight)
      const scrolled = this.scrollParent.scrollTop
      const scrolledToBottom = scrolled >= (bottom - 2) && scrolled <= (bottom + 2)
      if (forceScroll || scrolledToBottom) {
        this.$nextTick(() => {
          this.scrollParent.scrollTo(0, this.scrollParent.scrollHeight || document.body.scrollHeight)
        })
      }
    },
    findScrollParent (node) {
      const isScrolling = (node) => {
        const overflow = getComputedStyle(node, null).getPropertyValue('overflow-y')
        return overflow.indexOf('scroll') > -1 || overflow.indexOf('auto') > -1
      }
      if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return undefined
      }
      let current = node.parentNode
      while (current?.parentNode) {
        if (isScrolling(current)) {
          return current
        }
        current = current.parentNode
      }
      return window
    },
    promptUserAgain () {
      setTimeout(() => {
        this.history.push({})
        this.loading = true
        setTimeout(() => {
          this.history[this.history.length - 1] = {
            modelResponse: 'Anything else I can help you with?'
          }
          this.loading = false
        }, 1000)
      }, 500)
    },
    formatObjectType (str) {
      let returnStr = str
      returnStr = this.$filters.convertFromCapsUnderscore(returnStr)
      returnStr = returnStr.replaceAll('api', 'API')
      return returnStr
    },
    loadDataset (datasets, datasetIndex) {
      if (this.hasSessionStorage) {
        sessionStorage.removeItem('fluentGptDataset')
        sessionStorage.setItem('fluentGptDataset', JSON.stringify(datasets))
        this.$eventBus.$emit('closeMuseChatDrawer')
        this.$router.push({
          path: '/reporting/data-explorer/',
          query: {
            sessionDatasetId: datasetIndex,
            sessionDatasetCache: new Date().getTime()
          }
        })
      }
    }
  },
  mounted () {
    this.scrollParent = document.getElementById('fluentGptScrollArea')
    // focus was messing up splitpane animation
    setTimeout(() => {
      this.focusInput()
    }, 200)
  }
}
</script>
<style lang="scss">
.fluent-ai-chat {
  #fluentGptScrollArea {
    padding-right: var(--p-overlay-modal-padding);
    padding-left: var(--p-overlay-modal-padding);
  }
  .fluent-ai-input {
    padding-right: var(--p-overlay-modal-padding);
    padding-left: var(--p-overlay-modal-padding);
    #muse-chat-textarea:focus {
      box-shadow: none;
      border-color: $bionic-indigo;
    }
  }
  .user-prompt {
    background: #F5F8FF;
  }
  .user-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .bg-muse-purple {
    background: $muse-light-purple;
  }
  .stroke-white {
    svg path {
      stroke: #fff;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  .model-response {
    code {
      white-space: pre-line;
    }
    h1, h2, h3, h4, h4, h6 {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}
.text-bubble {
  border-radius: 20px;
  transition: all 0.3s ease;
  &.you {
    border-bottom-right-radius: 5px;
  }
  &.muse {
    border-top-left-radius: 5px;
  }
  &.suggestion:hover {
    background: #555;
    color: #fff;
  }
}
.prompt-html + .prompt-loader {
  margin-top: 1rem;
}
</style>
