<template>
  <div class="video-player">
    <t-modal :visible="modalVisible"
             @change="modalVisible = $event"
             lazy
             body-class="video-player-modal"
             title-class="d-flex align-items-center"
             ok-only
             ok-title="Got it"
             :hide-footer="true"
             @hidden="modalHidden()"
             @show="modalOpened()">
      <template #modal-title>
        <fluency-icon type="video" class="mr-3" />
        {{ selectedVideo.title }}
      </template>

      <template #default>
        <template v-if="loading">
          <fluency-loader />
        </template>
        <template v-else-if="selectedVideo">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe :src="`${selectedVideo.videoUrl}?autoplay=${autoPlay}`"
                    allowfullscreen />
          </div>
          <template v-if="showRelatedList">
            <h3 class="mt-5 mb-3">"{{selectedVideo.section}}" Videos:</h3>
            <ul class="list-unstyled list-inline">
              <template v-for="(video, videoKey) in videoLibrary" :key="videoKey">
                <li v-if="video.section === selectedVideo.section"
                    class="list-inline-item">
                  <p-chip :class="{ 'active': (video.videoId === selectedVideo.videoId) }"
                          class="video-badge border badge-full cursor-pointer py-3 px-4 my-2"
                          @click="playVideo(video.videoId)">{{video.title}}</p-chip>
                </li>
              </template>
            </ul>
          </template>
        </template>
        <template v-else>
          <p class="alert alert-warning">No video selected</p>
        </template>
      </template>
    </t-modal>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import _sortBy from 'lodash.sortby'

// TODO as of 10/22/24 - This videoPlayer is opened by emitting 'video-player-open' and 'video-player-open-by-url'
//  on the global eventBus. The only component that does that is <ai-video> which isn't mounted anywhere.
//  Can we remove the <videoPlayer> component?

export default {
  name: 'videoPlayer',
  components: { FluencyLoader },
  data () {
    return {
      modalVisible: false,
      eventName: 'video-player-open',
      eventNameByUrl: 'video-player-open-by-url',
      loading: false,
      videoId: null,
      videoByUrl: null,
      autoPlay: 1,
      showRelatedList: true,
      videoLibraryType: null
    }
  },
  computed: {
    videoLibrary () {
      switch (this.videoLibraryType) {
        case 'account':
          return _sortBy(this.$store.getters.accountVideos, ['title'])
        case 'training':
        default:
          return _sortBy(this.$store.getters.trainingVideos, ['title'])
      }
    },
    selectedVideo () {
      let returnVid = null

      if (this.videoByUrl) {
        return {
          title: this.videoByUrl.title || 'Video',
          videoUrl: this.videoByUrl.url
        }
      }

      this?.videoLibrary.forEach(video => {
        if (video.videoId === this.videoId) {
          returnVid = video

          this.$res.set.trackUserEvent(`${this.videoLibraryType}-video`, video)
        }
      })

      return returnVid
    }
  },
  async mounted () {
    this.reset()
    this.$eventBus.$on(this.eventName, this.setupVideo)
    this.$eventBus.$on(this.eventNameByUrl, this.setupVideoByUrl)
  },
  beforeUnmount () {
    this.$eventBus.$off(this.eventName, this.setupVideo)
    this.$eventBus.$off(this.eventNameByUrl, this.setupVideoByUrl)
  },
  methods: {
    setupVideo (payload) {
      this.videoLibraryType = payload.type

      if (payload.type === 'account') {
        this.autoPlay = 0
      }

      this.playVideo(payload.id)
    },
    setupVideoByUrl (payload) {
      /*
       * NOTE: nothing is currently using the `playByUrl` method, so if you hook this up give it a little extra testing.
       * There should be a commended out example in `aiVideo.vue`.
       * - GT (Mar. 15, 2023)
       */
      this.videoByUrl = { title: payload.name, url: payload.url }
      this.playVideoByUrl()
    },
    reset () {
      this.videoId = null
    },
    modalHidden () {
      this.reset()
    },
    modalOpened () {
    },
    playVideo (videoId) {
      this.videoId = videoId
      this.showRelatedList = true
      this.modalVisible = true
    },
    playVideoByUrl () {
      this.autoPlay = 0
      this.showRelatedList = false
      this.modalVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .embed-responsive {
    height: 691px;
  }
  .video-badge:not(.active):hover {
    border-color: $fluency-lightgray !important;
    background-color: $fluency-lightergray;
  }
  .video-badge.active {
    border-color: $fluency-lightgray !important;
  }
</style>

<style lang="scss">
.video-player .video-player-modal {
  min-height: 791px;
}
</style>
