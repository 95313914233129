<template>
  <t-col v-if="value && show" cols="12" class="bp-preview-entry-wrap">
    <div class="bp-preview-entry d-flex my-2 pt-2">

      <template v-if="creative">
        <div class="td-kw-phrase pr-3">
          {{value[creativeKeys[0]]}}
        </div>
        <div class="td-kw-match-type pr-3">
          {{value[creativeKeys[1]]}}
        </div>
        <div class="td-kw-bid">
          {{$filters.currency(value[creativeKeys[2]], 2)}}
        </div>
      </template><!-- / creative -->
      <template v-else>
        <div class="td-label pr-3">
          <b class="label">{{bpLabel(label, config)}}</b>:
        </div>

        <div class="td-value pr-3">
          <pre class="value mb-0 text-100" :class="computedClass">{{bpValue(value, label)}}</pre>
        </div>
      </template><!-- / !creative -->

      <div class="td-tags">
        <div v-if="recognizedTags">
          <ul class="list-unstyled list-inline mb-0">
            <li v-for="(tag, tagKey) in recognizedTags"
                :key="tagKey"
                @click="showTagValuesPopover($event, tag)"
                class="list-inline-item mx-2 my-1">
              <p-chip :id="`link-${label}-${tagKey}`"
                       class="cursor-pointer badge-full px-3 text-nowrap">
                <samp>{{tag.text}}</samp>
              </p-chip>
            </li>
            <p-popover ref="tagValuesPopover"
                       :base-z-index="11200"
                       :append-to="'body'">
              <div v-if="popoverTag">
                <h3>"{{popoverTag.text}}" values:</h3>
                <div v-for="(val, valKey) in popoverTag.values" :key="valKey" :class="{ 'font-weight-bold': meta && val === meta.get(popoverTag.text)}">{{val}}</div>

              </div>
            </p-popover>
          </ul>
        </div>
      </div>

    </div>
  </t-col>
</template>

<script>

export default {
  name: 'blueprintPreviewerField',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      default: null
    },
    show: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => { return {} }
    },
    tagData: {
      type: Object,
      default: () => { return {} }
    },
    highlight: {
      type: Boolean,
      default: false
    },
    creative: {
      type: Boolean,
      default: false
    },
    creativeKeys: {
      type: Array,
      default: () => { return [] }
    },
    meta: {
      type: Map,
      default: null
    }
  },
  data () {
    return {
      exampleLimit: 10,
      popoverTag: null
    }
  },
  computed: {
    computedClass () {
      if (!this.highlight) {
        return null
      }

      if (this.label === 'status') {
        if (this.value === 'ENABLED') {
          return 'text-success font-weight-bold'
        }

        return 'text-danger font-weight-bold'
      }

      return null
    },
    recognizedTags () {
      if (!this.value) {
        return []
      }
      if (!this.creative && typeof this.value !== 'string') {
        return []
      }

      // const str = 'This [is] some [text [with tags] more text] blah [blah] blah'
      const str = (this.creative) ? this.value[this.creativeKeys[0]] : this.value

      function* parse (str) {
        const indices = []
        for (let i = 0; i < str.length; ++i) {
          if (str[i] === '[') {
            indices.push(i)
          } else if (str[i] === ']') {
            const idx = indices.pop()
            yield str.slice(idx + 1, i)
            yield str.slice(idx, i + 1)
          }
        }
      }

      const arr = [...parse(str)].filter(n => {
        return !n.startsWith('[') && !n.endsWith(']')
      })

      const returnVal = []

      arr.forEach(v => {
        const cv = this.pullTagData(v)

        if (cv) {
          returnVal.push({ text: v, values: cv })
        }
      })

      return returnVal
    }
  },
  methods: {
    showTagValuesPopover (nativeClickEvent, tag) {
      this.popoverTag = tag
      this.$refs.tagValuesPopover.show(nativeClickEvent)
    },
    pullTagData (str) {
      const types = ['fluency', 'global', 'strategy', 'account'] // preserve order
      let returnVal = false

      types.forEach(k => {
        if (this.tagData[k]?.[str]) {
          returnVal = this.tagData[k][str]
        }
      })

      return returnVal
    },
    bpLabel (val, blueprintConfig) {
      if (blueprintConfig?.[val]) {
        return blueprintConfig[val].displayName
      }

      switch (val) {
        case 'templateName':
          return 'Name'
        case 'contentTemplateId':
          return 'Blueprint ID'
        case 'updatedOn':
          return 'Last Updated'
      }

      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
    },
    bpValue (val, label) {
      if (label === 'updatedOn') {
        return this.$moment(val).format('MMM DD, YYYY [at] HH:mm A')
      }

      return val
    }
  }
}
</script>

<style lang="scss" scoped>
  .bp-preview-entry {
    border-top: 1px solid $fluency-lightergray;
  }

  .td-label {
    width: 140px;
    text-align: right;
  }
  .td-value {
    width: 500px;
  }
  .td-tags {
    width: auto;
  }
</style>
