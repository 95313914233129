<template>
  <div v-if="!holdRender"
       :class="backpackLayoutWrapperClasses">

    <!-- NAVIGATION ---------------------------------------------------------->
    <template v-if="user">
      <div class="bionic-navigation">
        <user-mode-switcher :short-key-binding="true" />
        <bionic-navigation />
      </div>
    </template>

    <!-- MAIN PAGE CONTENT --------------------------------------------------->
    <div class="bionic-content"
         :class="[slotClasses]">
      <div v-if="hasContextSlot" class="bionic-content-context d-flex-center-start">
        <slot name="context" />
      </div>
      <div v-if="hasMetricsSlot" class="bionic-content-metrics d-flex-center-start">
        <slot name="metrics" />
      </div>
      <div class="bionic-content-main">
        <div class="bionic-page">
          <scroll-tracker />
          <global-toast/>
          <blueprint-previewer />
          <video-player />

          <!-- MAIN CONTENT SLOT -->
          <splitpanes class="bionic-theme justify-content-end"
                      @resize="panelWidth = $event[0].size">
            <pane v-if="!museModal && museOpen"
                  :size="panelWidth" class="xxxxxx">
              <ai-panel-display></ai-panel-display>
            </pane>
            <pane>
              <div class="vh-100 overflow-auto">
                <slot />
              </div>
            </pane>
          </splitpanes>
          <settings-drawer />
          <ai-modal-display v-if="museModal"></ai-modal-display>
          <confirm-alert />
          <prompt />
        </div>
      </div>
      <div v-if="hasFooterSlot" class="bionic-content-footer d-flex-center-start">
        <slot name="footer" />
      </div>

      <what-fix v-if="whatFixEnabled" />
      <template v-else>
        <forum-modal v-if="$route.name !=='insights-feedback'" />
      </template>
    </div>
  </div>
</template>

<script>
import ScrollTracker from '@/components/common/scrollTracker'
import VideoPlayer from '../components/common/videoPlayer'
import BlueprintPreviewer from '../components/common/blueprintPreviewer'
import SettingsDrawer from '@/components/pages/manage/settingsDrawer.vue'
import ConfirmAlert from 'core-ui/components/confirmAlert'
import Prompt from 'core-ui/components/prompt'
import WhatFix from '../components/common/thirdparty/whatFix.vue'
import GlobalToast from '@/components/common/globalToast'
import { defineAsyncComponent } from 'vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import BionicNavigation from '@/components/bionic/bionicNavigation.vue'
import { useMusePanel } from 'core-ui/composables/useMusePanel'
import AiPanelDisplay from '../components/common/aiPanelDisplay.vue'
import AiModalDisplay from '../components/common/aiModalDisplay.vue'
import UserModeSwitcher from "../components/common/userModeSwitcher.vue";
Splitpanes.compatConfig = { MODE: 2 }
Pane.compatConfig = { MODE: 2 }
const pagesToRetainSelectedRows = [
  '/reporting/data-explorer/',
  '/bulk/',
  '/bulk/receipt/'
]
export default {
  name: 'default',
  components: {
    UserModeSwitcher,
    SettingsDrawer,
    ForumModal: defineAsyncComponent(() => import('@/components/common/forum/forumModal')),
    WhatFix,
    VideoPlayer,
    BlueprintPreviewer,
    ScrollTracker,
    ConfirmAlert,
    Prompt,
    BionicNavigation,
    GlobalToast,
    Pane,
    Splitpanes,
    AiPanelDisplay,
    AiModalDisplay
  },
  setup () {
    const { museOpen, panelWidth, museModal } = useMusePanel()
    return { museOpen, panelWidth, museModal }
  },
  data () {
    return {
      debug: {
        leftNav: false
      },
      isDev: process.env.NODE_ENV === 'development',
      holdRender: true
    }
  },
  async created () {
    if (this.$authn.isAuthenticated() && this.$store.getters.user?.email?.includes('fluency')) {
      const customer = await this.$res.fetch.getCustomerFromUrl(`${window.location.origin}${this.$route.fullPath}`)
      if (customer &&
        customer.customerId &&
        customer.customerId !== this.$store.getters.user?.customerId &&
        customer.customerId !== this.$store.getters.actingAsUser) {
        this.$store.commit('setActingAsUser', customer.customerId)
        await this.$store.dispatch('clearCustomerSpecificLocalStore')
        setTimeout(() => this.$toast(`Switched to ${customer.name}`, 'success'), 1000)
      }
    }
    this.holdRender = false
    if (!this.$store.getters.user?.userRegistryId) {
      await this.$authn.fetchUser()
    }

    await this.init()

    this.registerFsEnvRoutingHandler()
  },
  mounted () {
    this.toggleHtmlBodyClass()
  },
  computed: {
    modeClass () {
      const userClass = (this.user && this.user.hasOwnProperty('isFluencyUser') && this.user.isFluencyUser) ? 'fluency-user' : 'client-user'
      return `view-${this.$store.getters.userMode} ${userClass}`
    },
    envClass () {
      return `backpack-${this.$store.getters.nodeEnv}`
    },
    user () {
      return this.$store.getters.user
    },
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    whatFixEnabled () {
      return this.user?.capabilities?.WhatFix
    },
    AiChat () {
      return this.user?.capabilities?.AIChat
    },
    backpackLayoutWrapperClasses () {
      const baseClasses = ['backpack-app', this.envClass, this.modeClass]

      baseClasses.push('bionic-container')

      return baseClasses
    },
    hasContextSlot () {
      return !!this.$slots.context
    },
    hasMetricsSlot () {
      return !!this.$slots.metrics
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    },
    slotClasses () {
      const arr = []

      arr.push((this.hasContextSlot) ? 'has-context' : 'no-context')
      arr.push((this.hasMetricsSlot) ? 'has-metrics' : 'no-metrics')
      arr.push((this.hasFooterSlot) ? 'has-footer' : 'no-footer')

      return arr.join(' ')
    }
  },
  methods: {
    registerFsEnvRoutingHandler () {
      this.$router.beforeEach((to, from, next) => {
        console.log(to.path, from.path)
        if (to.path !== from.path && !(pagesToRetainSelectedRows.includes(to.path) || pagesToRetainSelectedRows.includes(from.path)) && this.$store.getters['table/hasSelectedRows']) {
          this.$store.commit('table/clearAllSelectedRows')
        }
        const envKey = 'fsEnv'
        const ipKey = 'ip'
        if (to.query[envKey] !== from.query[envKey] || to.query[ipKey] !== from.query[ipKey]) {
          const nextRoute = {
            ...to,
            query: { ...to.query }
          }
          if (from.query[envKey]) {
            nextRoute.query[envKey] = from.query[envKey]
          }
          if (from.query[ipKey]) {
            nextRoute.query[ipKey] = from.query[ipKey]
          }
          next(nextRoute)
        } else {
          next()
        }
      })
    },
    async init () {
      if (!this.$store.state.app.init && this.$authn.isAuthenticated()) {
        await this.$store.dispatch('init')
      }
    },
    toggleHtmlBodyClass () {
      const el = document.body
      el.classList.add('bionic')
    }
  }
}
</script>

<!-- BIONIC GLOBAL STYLES -->
<style lang="scss">
body.bionic {
  overflow: hidden;
}
.bionic-page {
  overflow: auto;
  height: 100%;
  width: 100%;
}
.bionic-container {
  display: grid;
  //grid-template-columns: 81px 1fr;
  grid-template-columns: 65px 1fr;
  height: 100vh;
  width: 100vw;
  background-color: white;
  transition: grid-template-columns 200ms linear;

  //&.nav-panel-open {
  //  grid-template-columns: 367px 1fr;
  //}
}
.bionic-navigation {}
.bionic-content {
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  background-color: var(--p-surface-25);

  $bionic-context-min: 50px;
  $bionic-metrics-min: 75px;
  $bionic-footer-min: 40px;

  &.has-context {
    &.has-metrics.has-footer { grid-template-rows: minmax($bionic-context-min, auto) minmax($bionic-metrics-min, auto) 1fr minmax($bionic-footer-min, auto); }
    &.has-metrics.no-footer { grid-template-rows: minmax($bionic-context-min, auto) minmax($bionic-metrics-min, auto) 1fr; }
    &.no-metrics.has-footer { grid-template-rows: minmax($bionic-context-min, auto) 1fr minmax($bionic-footer-min, auto); }
    //&.no-metrics.no-footer { grid-template-rows: 50px 1fr; }
    &.no-metrics.no-footer { grid-template-rows: minmax($bionic-context-min, auto) 1fr; }
  }

  &.no-context {
    &.has-metrics.has-footer { grid-template-rows: minmax($bionic-metrics-min, auto) 1fr minmax($bionic-footer-min, auto); }
    &.has-metrics.no-footer { grid-template-rows: minmax($bionic-metrics-min, auto) 1fr; }
    &.no-metrics.has-footer { grid-template-rows: 1fr minmax($bionic-footer-min, auto); }
  }

  .bionic-content-context,
  .bionic-content-metrics,
  .bionic-content-footer {
    padding: 0 0 0 15px;
  }
  .bionic-content-main {
    overflow: hidden;
    //padding: 10px 16px 0;
  }
  .bionic-content-footer {
    border-top: 1px solid #e7e9ee;
  }
}
body.bionic {
  .notification-container {
    width: calc(100% - 65px) !important;
    left: 65px !important;
  }
}
</style>

<!-- SCOPED STYLES (DEFAULT V1) -->
<style scoped lang="scss">
  .delayed-message {
    opacity:0;
    animation: fade-in ease-in-out 2s;
    animation-fill-mode: forwards;
  }
</style>
